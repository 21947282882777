var cw = (cw || {});

(function ($) {

    "use strict";

    function ImageHelper() {

        var self = this;

        this.init = function () {
            self.ieFallbackObjectCover();
        };

        this.ieFallbackObjectCover = function () {
            if('objectFit' in document.documentElement.style === false) {
                var $imageWrapper = $('.ie-objectfit-fallback');

                $imageWrapper.each(function (k,v) {
                    var $image = $(v).find('img');
                    var imageSource = $image.attr('src');
                    if (!imageSource) {
                        imageSource = $image.data('src');
                    }
                    $image.hide();
                    $(v).css({
                        backgroundImage: 'url('+ imageSource + ')',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center'
                    });
                });
            }
        };
    }

    $(document).ready(function () {
        cw.imageHelper = new ImageHelper();
        cw.imageHelper.init();
    });

})(jQuery);